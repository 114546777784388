import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

import SearchNormalIcon from "../../assets/SearchNormalIcon";
import {
  Box,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputAdornment,
  Paper,
  Container,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Autocomplete,
  Snackbar,
  debounce,
} from "@mui/material";

import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import "./Template.scss";

import { DataGrid } from "@mui/x-data-grid";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import FileUploadBg from "../../assets/FileUploadBg";
import BackArrowIcon from "../../assets/BackArrowIcon";
import { Check, Close } from "@mui/icons-material";
import { updateTemplatePagination } from "../../redux/slices/dataTableSlice";
import ResizableTable from "../../components/dataTable/resizableTable";
import {
  createTemplate,
  getSendgridTemplate,
  getTemplateTable,
  getTemplateType,
} from "../../redux/actions/gtm/templateActions";
import moment from "moment";
import {
  updateTemplateName,
  updateTemplateSnack,
} from "../../redux/slices/gtm/gtmSlice";

function TemplateTable() {
  const { template_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );
  const { template_details } = useSelector((state) => state.gtmReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      header: "Name",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "created_at",
      header: "Created At",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()
              ? moment(cell.getValue()).format("MMM DD YYYY HH:mm")
              : "-"}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "updated_at",
      header: "Updated At",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()
              ? moment(cell.getValue()).format("MMM DD YYYY HH:mm")
              : "-"}
          </div>
        </Tooltip>
      ),
    },
  ]);

  const rows = template_details
    ? template_details.items?.map((dataItem, index) => {
        const row = { id: dataItem.uuid };

        columns?.forEach((column) => {
          row[column.accessorKey] = dataItem[column.accessorKey];
        });
        return row;
      })
    : [];

  const handlePaginationModelChange = (newPaginationModel) => {
    dispatch(updateTemplatePagination(newPaginationModel));
  };
  const handleRowClick = (params) => {};

  return (
    <>
      <>
        <Grid marginTop="20px">
          <ResizableTable
            rowCount={template_details?.total ?? 0}
            onTablePaginationChange={handlePaginationModelChange}
            onTableRowClick={handleRowClick}
            data={rows}
            columns={columns}
            paginationDetails={template_paginationDetails}
          />
        </Grid>
      </>
    </>
  );
}

function Template() {
  const { template_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );

  const { template_name, template_types, template_snack_message } = useSelector(
    (state) => state.gtmReducer
  );
  const [age, setAge] = React.useState("");
  const dispatch = useDispatch();
  const [templateId, setTemplateId] = useState("");
  const [type, setType] = useState(null);
  const [search, setSearch] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    dispatch(updateTemplateName(""));
    setTemplateId("");
    setType(null);
  };

  useEffect(() => {
    dispatch(getTemplateType());
  }, []);

  const handleCreateTemplate = () => {
    let data = {
      template_id: templateId,
      template_name,
      template_type_uuid: type.uuid,
    };
    dispatch(createTemplate(data));
    toggleDrawer("right", false)();
  };
  const [openSnack, setOpenSnack] = useState(false);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );

  useEffect(() => {
    if (template_snack_message.length) {
      setOpenSnack(true);
      const timer = setTimeout(() => {
        dispatch(updateTemplateSnack(""));
        setOpenSnack(false);
      }, 3500);

      return () => clearTimeout(timer);
    }
  }, [template_snack_message]);
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 500 }}
      role="presentation"
    >
      <Grid sx={{ m: "12px" }}>
        <Grid className="textGridwrap">
          <IconButton
            className="closeBtn"
            onClick={toggleDrawer(anchor, false)}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Typography>Template Details</Typography>
          <div className="d-flex mb30">
            <TextField
              className="textfieldWrap"
              sx={{ width: "100%" }}
              label="Template Id"
              helperText={
                template_name.length === 0
                  ? "Fill the ID and press return/enter to get template name."
                  : ""
              }
              sx={{
                "& .MuiFormHelperText-root": {
                  fontSize: "10px",
                  fontWeight: "300",
                  mb: 0,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              value={templateId}
              onChange={(e) => setTemplateId(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter")
                  dispatch(getSendgridTemplate(e.target.value));
              }}
            />
          </div>
          <div className="d-flex mb30">
            <TextField
              className="textfieldWrap"
              sx={{ width: "100%" }}
              label="Template Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              value={template_name}
              onChange={(e) => dispatch(updateTemplateName(e.target.value))}
            />
          </div>
          <div className="d-flex">
            <Autocomplete
              disablePortal
              options={template_types}
              fullWidth
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => setType(newValue)}
              isOptionEqualToValue={(option, value) =>
                option.uuid === value.uuid
              }
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Template Type"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
        </Grid>
        <Grid className="footerBtn">
          <Button
            onClick={handleCreateTemplate}
            disabled={!(templateId.length && template_name.length && type)}
          >
            <CreateOutlinedIcon /> Create
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
  const handleSearchContactChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleDebounceChange = debounce(handleSearchContactChange, 2000);
  useEffect(() => {
    // Function to fetch template table data
    const fetchTemplateTableData = () => {
      const data = {
        search: search,
        page: parseInt(template_paginationDetails.page) + 1,
        size: template_paginationDetails.pageSize,
      };
      dispatch(getTemplateTable(data)); // Dispatch action to fetch template table data
    };

    // Call fetchTemplateTableData initially
    fetchTemplateTableData();

    // Set up an interval to call fetchTemplateTableData every 20 seconds
    const intervalId = setInterval(fetchTemplateTableData, 20000);

    // Clean up the interval on component unmount or when the dependencies change
    return () => clearInterval(intervalId);
  }, [
    template_paginationDetails.page,
    template_paginationDetails.pageSize,
    search,
  ]);

  return (
    <div
      className="templateWrap"
      style={{
        overflow: "scroll",
        height: "calc(100vh - 70px)",
        width: "86vw",
      }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Template
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Template customer experience!
            </Typography>
          </div>
        </div>

        <Grid style={{ display: "flex" }} marginBottom="16px">
          <Grid sx={{ width: "100%" }}>
            <TextField
              className="textfieldWrap"
              sx={{ width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchNormalIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleDebounceChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") setSearch(e.target.value);
              }}
              placeholder="Search here"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid className="topWrap" sx={{ ml: "10px" }}>
            <Button onClick={toggleDrawer("right", true)}>
              <ControlPointRoundedIcon /> Create Template
            </Button>
          </Grid>
        </Grid>

        <div style={{ height: "70vh", width: "100%" }}>
          <TemplateTable />
        </div>
        <div>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </div>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnack}
        message={template_snack_message}
        action={action}
        onClose={handleSnackBarClose}
        autoHideDuration={3500}
      />
    </div>
  );
}

export default Template;
