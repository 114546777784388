import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import SearchNormalIcon from "../../assets/SearchNormalIcon";
import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  Container,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Snackbar,
  debounce,
} from "@mui/material";

import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import "./Contact.scss";

import { DataGrid } from "@mui/x-data-grid";
import FileIcon from "../../assets/FileIcon";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import FileUploadBg from "../../assets/FileUploadBg";
import BackArrowIcon from "../../assets/BackArrowIcon";
import ResizableTable from "../../components/dataTable/resizableTable";
import { updateContactPagination } from "../../redux/slices/dataTableSlice";
import {
  getContacts,
  insertContact,
  uploadFile,
} from "../../redux/actions/gtm/contactActions";
import { Check, Circle, Close } from "@mui/icons-material";
import { updateContactSnackMessage } from "../../redux/slices/gtm/gtmSlice";
import { downloadSampleFile } from "../../redux/actions/gtm/campaignActions";

function ContactTable() {
  const { contact_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );
  const { contact_details } = useSelector((state) => state.gtmReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      accessorKey: "first_name",
      header: "First Name",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "last_name",
      header: "Last Name",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "full_name",
      header: "Full Name",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "email1",
      header: "Email 1",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "email2",
      header: "Email 2",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "email3",
      header: "Email 3",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "is_active",
      header: "Is Active",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue() ? (
                <Circle sx={{ color: "green",fontSize:"10px" }} />
              ) : (
                <Circle sx={{ color: "red",fontSize:"10px" }} />
            )}
          </div>
        </Tooltip>
      ),
    },
  ]);

  const rows = contact_details
    ? contact_details.items?.map((dataItem, index) => {
        const row = { id: dataItem.uuid };

        columns?.forEach((column) => {
          row[column.accessorKey] = dataItem[column.accessorKey];
        });
        return row;
      })
    : [];

  const handlePaginationModelChange = (newPaginationModel) => {
    dispatch(updateContactPagination(newPaginationModel));
  };
  const handleRowClick = (params) => {};

  return (
    <>
      <>
        <Grid  marginTop="20px">
          <ResizableTable
            rowCount={contact_details?.total ?? 0}
            onTablePaginationChange={handlePaginationModelChange}
            onTableRowClick={handleRowClick}
            data={rows}
            columns={columns}
            paginationDetails={contact_paginationDetails}
          />
        </Grid>
      </>
    </>
  );
}

function Contact() {
  const [file, setFile] = useState(null);
  const [search,setSearch]=useState("")
  const [searchContact,setSearchContact]=useState("")
  const dispatch = useDispatch();
  const { contact_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );
  const { file_uuid,contact_snack_message } = useSelector((state) => state.gtmReducer);
  const [openSnack, setOpenSnack] = useState(false);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );
  useEffect(() => {
    if (contact_snack_message.length) {
      setOpenSnack(true);
      const timer = setTimeout(() => {
        dispatch(updateContactSnackMessage(""));
        setOpenSnack(false);
      }, 3500);

      return () => clearTimeout(timer);
    }
  }, [contact_snack_message]);
  const handleFileChange = (event) => {
    const newFile = event.target.files[0];
    console.log(newFile, newFile);
    setFile(newFile);
    dispatch(uploadFile(newFile));
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setFile(null)
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 500 }}
      role="presentation"
    >
      <Grid sx={{ m: "12px" }}>
        <Grid className="fileUploadSection">
          <IconButton
            className="closeBtn"
            onClick={toggleDrawer(anchor, false)}
          >
            <CloseOutlinedIcon />
          </IconButton>
          {file ? (
            <div className="progresswrap">
              <div className="progressInner">
                <div className="progressStatus">
                  <div className="progressDiv">
                    {/* <FileUploadBg /> */}
                    <FileIcon />
                    <progress
                      class="percentage"
                      title={file.name}
                      max="10"
                      value={file ? (file_uuid ? "10" : "5") : "0"}
                    ></progress>
                    <span className="removeBtn" onClick={() => setFile(null)}>
                      x Remove
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div class="fileWrap">
              <Typography>Import the Contact details</Typography>
              <div class="uploadContainer">
                <FileUploadBg />
                <input
                  type="file"
                  id="file_upload"
                  accept=".xls,.xlsx,.csv"
                  onChange={handleFileChange}
                />
              </div>
              <span>Supported file formats : xls, xslx, csv</span>
            </div>

          )}
          <Grid display={"flex"} justifyContent={"flex-end"} onClick={()=>dispatch(downloadSampleFile())}>
          <Typography sx={{fontSize:"12px",textDecoration:"underline",color:"blue",cursor:"pointer"}}>
            Downlod Sample File
          </Typography>
          </Grid>
        </Grid>
        <Grid className="footerBtn">
          <Button
            onClick={() => {
              toggleDrawer(anchor, false)();
              dispatch(insertContact(file_uuid));
            }}
            disabled={!(file&&file_uuid)}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
  const handleSearchContactChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleDebounceChange = debounce(handleSearchContactChange, 2000);

  useEffect(() => {
    // Function to fetch contacts data
    const fetchContactsData = () => {
        const data = {
            search: search,
            page: parseInt(contact_paginationDetails.page) + 1,
            size: contact_paginationDetails.pageSize,
        };
        dispatch(getContacts(data)); // Dispatch action to fetch contacts data
    };

    // Call fetchContactsData initially
    fetchContactsData();

    // Set up an interval to call fetchContactsData every 20 seconds
    const intervalId = setInterval(fetchContactsData, 20000);

    // Clean up the interval on component unmount or when the dependencies change
    return () => clearInterval(intervalId);
}, [contact_paginationDetails.page, contact_paginationDetails.pageSize, search]);

  return (
    <div
      className="contactWrap"
      style={{
        overflow: "scroll",
        height: "calc(100vh - 70px)",
        width: "86vw",
      }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Contact
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Contact customer experience!
            </Typography>
          </div>
        </div>

        <Grid style={{ display: "flex" }} marginBottom="16px">
          <Grid sx={{ width: "100%" }}>
            <TextField
              className="textfieldWrap"
              sx={{ width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchNormalIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleDebounceChange}
              onKeyDown={(e)=>{if(e.key==="Enter")setSearch(e.target.value)}}
              placeholder="Search here"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid className="topWrap contactBtn" sx={{ mx: "10px" }}>
            <Button onClick={toggleDrawer("right", true)}>
              <UploadRoundedIcon /> Import
            </Button>
          </Grid>

          {/* <Grid className="topWrap contactBtn">
            <Button>
              <DownloadRoundedIcon /> Export
            </Button>
          </Grid> */}
        </Grid>

        <div style={{ height: "70vh", width: "100%" }}>
          <ContactTable />
        </div>
        <div>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </div>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnack}
        message={contact_snack_message}
        action={action}
        onClose={handleSnackBarClose}
        autoHideDuration={3500}
      />
    </div>
  );
}

export default Contact;
