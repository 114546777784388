import React from 'react'

function FileIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.3335 13.5865H23.4802C20.3202 13.5865 17.7468 11.0132 17.7468 7.85317V3.99984C17.7468 3.2665 17.1468 2.6665 16.4135 2.6665H10.7602C6.6535 2.6665 3.3335 5.33317 3.3335 10.0932V21.9065C3.3335 26.6665 6.6535 29.3332 10.7602 29.3332H21.2402C25.3468 29.3332 28.6668 26.6665 28.6668 21.9065V14.9198C28.6668 14.1865 28.0668 13.5865 27.3335 13.5865ZM15.3735 18.0398C15.1735 18.2398 14.9202 18.3332 14.6668 18.3332C14.4135 18.3332 14.1602 18.2398 13.9602 18.0398L13.0002 17.0798V22.6665C13.0002 23.2132 12.5468 23.6665 12.0002 23.6665C11.4535 23.6665 11.0002 23.2132 11.0002 22.6665V17.0798L10.0402 18.0398C9.6535 18.4265 9.0135 18.4265 8.62683 18.0398C8.24016 17.6532 8.24016 17.0132 8.62683 16.6265L11.2935 13.9598C11.3868 13.8798 11.4802 13.8132 11.5868 13.7598C11.6135 13.7465 11.6535 13.7332 11.6802 13.7198C11.7602 13.6932 11.8402 13.6798 11.9335 13.6665C11.9735 13.6665 12.0002 13.6665 12.0402 13.6665C12.1468 13.6665 12.2535 13.6932 12.3602 13.7332C12.3735 13.7332 12.3735 13.7332 12.3868 13.7332C12.4935 13.7732 12.6002 13.8532 12.6802 13.9332C12.6935 13.9465 12.7068 13.9465 12.7068 13.9598L15.3735 16.6265C15.7602 17.0132 15.7602 17.6532 15.3735 18.0398Z" fill="#1A73E8"/>
<path d="M23.2399 11.7468C24.5066 11.7602 26.2666 11.7602 27.7732 11.7602C28.5332 11.7602 28.9332 10.8668 28.3999 10.3335C26.4799 8.40015 23.0399 4.92015 21.0666 2.94682C20.5199 2.40015 19.5732 2.77349 19.5732 3.53349V8.18682C19.5732 10.1335 21.2266 11.7468 23.2399 11.7468Z" fill="#1A73E8"/>
</svg>
  )
}

export default FileIcon