import { configureStore } from "@reduxjs/toolkit";
import gtmSlice from "../slices/gtm/gtmSlice";
import dataTableSlice from "../slices/dataTableSlice";



const store = configureStore({
    reducer:{
       
   
        gtmReducer:gtmSlice,
        dataTableReducer:dataTableSlice


    }
})

export default store