import React from "react";

function SearchNormalIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16699 17.291C4.68366 17.291 1.04199 13.6493 1.04199 9.16602C1.04199 4.68268 4.68366 1.04102 9.16699 1.04102C13.6503 1.04102 17.292 4.68268 17.292 9.16602C17.292 13.6493 13.6503 17.291 9.16699 17.291ZM9.16699 2.29102C5.37533 2.29102 2.29199 5.37435 2.29199 9.16602C2.29199 12.9577 5.37533 16.041 9.16699 16.041C12.9587 16.041 16.042 12.9577 16.042 9.16602C16.042 5.37435 12.9587 2.29102 9.16699 2.29102Z"
        fill="#071942"
        fillOpacity="0.32"
      />
      <path
        d="M16.7996 18.9917C16.7329 18.9917 16.6663 18.9833 16.6079 18.975C16.2163 18.925 15.5079 18.6583 15.1079 17.4667C14.8996 16.8417 14.9746 16.2167 15.3163 15.7417C15.6579 15.2667 16.2329 15 16.8913 15C17.7413 15 18.4079 15.325 18.7079 15.9C19.0079 16.475 18.9246 17.2083 18.4496 17.9167C17.8579 18.8083 17.2163 18.9917 16.7996 18.9917ZM16.2996 17.075C16.4413 17.5083 16.6413 17.725 16.7746 17.7417C16.9079 17.7583 17.1579 17.6 17.4163 17.225C17.6579 16.8667 17.6746 16.6083 17.6163 16.4917C17.5579 16.375 17.3246 16.25 16.8913 16.25C16.6329 16.25 16.4413 16.3333 16.3329 16.475C16.2329 16.6167 16.2163 16.8333 16.2996 17.075Z"
        fill="#071942"
        fillOpacity="0.32"
      />
    </svg>
  );
}

export default SearchNormalIcon;
