import axios from "../../../utils/axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const createTemplate = createAsyncThunk(
  "createTemplate",
  async (data, thunkAPI) => {
    const response = await axios(
      process.env.REACT_APP_GTM +
        `template/`,
      {
        method: "POST",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
        data:{
          template_id:data.template_id,
          template_name:data.template_name,
          template_type_uuid:data.template_type_uuid
        }
        
      }
    );
    return { data: response.data, status: response.status };
  }
  
);
  export const getTemplateTable = createAsyncThunk(
    "getTemplateTable",
    async (data, thunkAPI) => {
      const response = await axios(
        process.env.REACT_APP_GTM +
          `template/all?search=${data.search}&page=${data.page}&size=${data.size}`,
        {
          method: "GET",
          headers: {
            accept: "application/json,text/plain",
            "Content-Type": "application/json",
          },
          
        }
      );
      return { data: response.data, status: response.status };
    }
    
  );

  export const getTemplateType = createAsyncThunk(
    "getTemplateType",
    async (thunkAPI) => {
      const response = await axios(
        process.env.REACT_APP_GTM +
          `template/type`,
        {
          method: "GET",
          headers: {
            accept: "application/json,text/plain",
            "Content-Type": "application/json",
          },
          
        }
      );
      return { data: response.data, status: response.status };
    }
    
  );
  export const getSendgridTemplate = createAsyncThunk(
    "getSendGridTemplate",
    async (template_id, thunkAPI) => {
      const response = await axios(
        process.env.REACT_APP_GTM +
          `template/sendgrid?template_id=${template_id}`,
        {
          method: "GET",
          headers: {
            accept: "application/json,text/plain",
            "Content-Type": "application/json",
          },
          
        }
      );
      return { data: response.data, status: response.status };
    }
    
  );


 