import { createSlice } from "@reduxjs/toolkit";
import {
  getContacts,
  insertContact,
  uploadFile,
} from "../../actions/gtm/contactActions";
import {
  createTemplate,
  getSendgridTemplate,
  getTemplateTable,
  getTemplateType,
} from "../../actions/gtm/templateActions";
import {
  createCampaign,
  getAllEmailDomains,
  getCampaigns,
  runCampaign,
} from "../../actions/gtm/campaignActions";
import { act } from "react-dom/test-utils";

const gtmSlice = createSlice({
  name: "gtmSlice",
  initialState: {
    contact_details: null,
    file_uuid: null,
    template_details: null,
    campaign_details: null,
    template_name: "",
    template_types: [],
    template_snack_message: "",
    contact_snack_message: "",
    campaign_snack_message: "",
    email_domains:[]
  },
  reducers: {
    updateTemplateName: (state, action) => {
      state.template_name = action.payload;
    },
    updateTemplateSnack: (state, action) => {
      state.template_snack_message = action.payload;
    },
    updateContactSnackMessage: (state, action) => {
      state.contact_snack_message = action.payload;
    },
    updateCampaignSnackMessage: (state, action) => {
      state.campaign_snack_message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContacts.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.contact_details = action.payload.data;
      })
      .addCase(getCampaigns.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.campaign_details = action.payload.data;
      })
      .addCase(uploadFile.pending, (state, action) => {
        state.file_uuid = null;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.file_uuid = action.payload.data.data;
        }
      })
      .addCase(getTemplateTable.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.template_details = action.payload.data;
        }
      })
      .addCase(getSendgridTemplate.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.template_name = action.payload.data.name;
      })
      .addCase(getTemplateType.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.template_types = action.payload.data;
      })

      .addCase(createTemplate.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.template_snack_message = "Template Created Successfully";
        else state.template_snack_message = action.payload.data.detail;
      })
      .addCase(runCampaign.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.campaign_snack_message = action.payload.data.message;
        else state.campaign_snack_message = action.payload.data.detail;
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.campaign_details = action.payload.data;
          state.campaign_snack_message = "Campaign Created Successfully";
        } else
          state.campaign_snack_message =
            "Error occured while creating campaign!";
      })
      .addCase(insertContact.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.contact_snack_message = action.payload.data.message;
        } else
          state.contact_snack_message =
            "Error occured while importing your file!";
      })
      .addCase(getAllEmailDomains.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.email_domains = action.payload.data;
        } else
           state.campaign_snack_message = "Erro fetching email domains!";
          
          
      });
  },
});

export const {
  updateTemplateName,
  updateTemplateSnack,
  updateContactSnackMessage,
  updateCampaignSnackMessage,
} = gtmSlice.actions;

export default gtmSlice.reducer;
