import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormControlLabel from "@mui/material/FormControlLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FileIcon from "../../assets/FileIcon";

import {
  Autocomplete,
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchNormalIcon from "../../assets/SearchNormalIcon";

import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";

import "./Campaign.scss";

import { Circle, MoreVert, PlayArrow } from "@mui/icons-material";
import moment from "moment";
import FileUploadBg from "../../assets/FileUploadBg";
import ResizableTable from "../../components/dataTable/resizableTable";
import {
  createCampaign,
  downloadSampleFile,
  getAllEmailDomains,
  getCampaigns,
  runCampaign,
} from "../../redux/actions/gtm/campaignActions";
import { uploadFile } from "../../redux/actions/gtm/contactActions";
import { getTemplateTable } from "../../redux/actions/gtm/templateActions";
import { updateCampaignPagination } from "../../redux/slices/dataTableSlice";
import { updateCampaignSnackMessage } from "../../redux/slices/gtm/gtmSlice";

function Campaign() {
  const [age, setAge] = React.useState("");
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [template, setTemplate] = useState(null);
  const [emailDomain, setEmailDomain] = useState(null);
  const [openRun, setOpenRun] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const handleFileChange = (event) => {
    const newFile = event.target.files[0];
    console.log(newFile, newFile);
    setFile(newFile);
    dispatch(uploadFile(newFile));
  };

  const { campaign_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );

  const { campaign_details } = useSelector((state) => state.gtmReducer);

  const { campaign_snack_message, file_uuid, template_details, email_domains } =
    useSelector((state) => state.gtmReducer);
  const [openSnack, setOpenSnack] = useState(false);
  const [campName, setCampName] = useState("");
  const [tempName, setTempName] = useState("");
  const [isExisting, setIsExisting] = useState("existing");
  const columns = useMemo(() => [
    {
      accessorKey: "name",
      header: "Name",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              width: "100%",
            }}
          >
            {cell.getValue() && (
              <div style={{ display: "flex", alignItems: "center" }}>
                {
                  <Circle
                    sx={{
                      color:
                        cell.getValue() === "Completed"
                          ? "green"
                          : cell.getValue() === "Failed"
                          ? "red"
                          : "blue",
                      fontSize: "10px",
                    }}
                  />
                }
              </div>
            )}
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {cell.getValue()}
            </div>
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "failed",
      header: "Failed",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "error",
      header: "Error",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "total_contacts",
      header: "Total Contacts",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()}
          </div>
        </Tooltip>
      ),
    },

    {
      accessorKey: "is_claimed",
      header: "Is Claimed",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue() ? (
              <Circle sx={{ color: "green", fontSize: "10px" }} />
            ) : (
              <Circle sx={{ color: "red", fontSize: "10px" }} />
            )}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "created_at",
      header: "Created At",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()
              ? moment(cell.getValue()).format("MMM DD YYYY HH:mm")
              : "-"}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "updated_at",
      header: "Updated At",
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cell.getValue()
              ? moment(cell.getValue()).format("MMM DD YYYY HH:mm")
              : "-"}
          </div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ cell }) => {
        const campaign_uuid = cell.row.original.id;
        console.log(campaign_uuid);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        return (
          <div
            key={campaign_uuid}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Button
              className="basicBtn"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              {" "}
              <MoreVert sx={{ color: "grey" }} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setSelectedCampaign(campaign_uuid);
                  handleClose();
                  setOpenRun(true);
                }}
              >
                <PlayArrow /> Run
              </MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ]);

  const rows = campaign_details
    ? campaign_details.items?.map((dataItem, index) => {
        const row = { id: dataItem.uuid };

        columns?.forEach((column) => {
          row[column.accessorKey] = dataItem[column.accessorKey];
        });
        return row;
      })
    : [];

  const handlePaginationModelChange = (newPaginationModel) => {
    dispatch(updateCampaignPagination(newPaginationModel));
  };
  const handleRowClick = (params) => {};

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );
  useEffect(() => {
    if (campaign_snack_message.length) {
      setOpenSnack(true);
      const timer = setTimeout(() => {
        dispatch(updateCampaignSnackMessage(""));
        setOpenSnack(false);
      }, 3500);

      return () => clearTimeout(timer);
    }
  }, [campaign_snack_message]);
  useEffect(() => {
    let data = {
      page: 1,
      size: 100,
      search: "",
    };
    dispatch(getTemplateTable(data));
    dispatch(getAllEmailDomains());
  }, []);
  useEffect(() => {
    // Function to fetch campaigns data
    const fetchCampaignsData = () => {
      const data = {
        page: parseInt(campaign_paginationDetails.page) + 1,
        size: campaign_paginationDetails.pageSize,
      };
      dispatch(getCampaigns(data)); // Dispatch action to fetch campaigns data
    };

    // Call fetchCampaignsData initially
    fetchCampaignsData();

    // Set up an interval to call fetchCampaignsData every 20 seconds
    const intervalId = setInterval(fetchCampaignsData, 20000);

    // Clean up the interval on component unmount or when the dependencies change
    return () => clearInterval(intervalId);
  }, [campaign_paginationDetails.page, campaign_paginationDetails.pageSize]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setCampName("");
    setTemplate(null);
    setFile(null);
    setEmailDomain(null);
  };
  const handleRunCampaign = () => {
    let data = {
      campaign_uuid: selectedCampaign,
      email_domain_uuid: emailDomain.uuid,
    };
    dispatch(runCampaign(data));
    setOpenRun(false);
    setEmailDomain(null);
    setSelectedCampaign(null);
  };
  const handleCreateCampaign = () => {
    let data = {
      campaign_name: campName,
      template_uuid: template.uuid,
      file_uuid: isExisting === "custom" ? file_uuid : null,
      page: parseInt(campaign_paginationDetails.page) + 1,
      size: campaign_paginationDetails.pageSize,
    };
    dispatch(createCampaign(data));
    toggleDrawer("right", false)();
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 500 }}
      role="presentation"
    >
      <Grid sx={{ m: "12px" }}>
        <Grid className="textGridwrap">
          <IconButton
            className="closeBtn"
            onClick={toggleDrawer(anchor, false)}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Typography>Campaign Details</Typography>
          <div className="d-flex mb30">
            <TextField
              className="textfieldWrap"
              value={campName}
              onChange={(e) => setCampName(e.target.value)}
              sx={{
                width: "100%",
                "& .MuiFormHelperText-root": {
                  fontSize: "10px",
                  fontWeight: "300",
                  mb: 0,
                },
              }}
              label="Campaign Name"
              helperText="The Campaign Name will be used as subject for sending emails."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              size="small"
              fullWidth
            />
          </div>
          <div className="d-flex mb30">
            <Autocomplete
              disablePortal
              options={template_details ? template_details.items : []}
              fullWidth
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => setTemplate(newValue)}
              isOptionEqualToValue={(option, value) =>
                option.uuid === value.uuid
              }
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Template Name"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
         
          <div className="d-flex mb30">
            <FormControl className="radioBtn">
              <RadioGroup
                value={isExisting}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="existing"
                name="radio-buttons-group"
                onChange={(e) => setIsExisting(e.target.value)}
              >
                <FormControlLabel
                  value="existing"
                  control={<Radio size="small" />}
                  label="For Existing Contact"
                />
                <FormControlLabel
                  value="custom"
                  control={<Radio size="small" />}
                  label="Custom File"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <Grid sx={{ m: "12px" }}>
            {isExisting === "custom" && (
              <Grid className="fileUploadSection">
                {file ? (
                  <div className="progresswrap1">
                    <div className="progressInner">
                      <div className="progressStatus">
                        <div className="progressDiv">
                          {/* <FileUploadBg /> */}
                          <FileIcon />
                          <progress
                            class="percentage"
                            title={file.name}
                            max="10"
                            value={file ? (file_uuid ? "10" : "5") : "0"}
                          ></progress>
                          <span
                            className="removeBtn"
                            onClick={() => setFile(null)}
                          >
                            x Remove
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div class="fileWrap campaignDrawer">
                    <Typography>Import the Contact details</Typography>
                    <div class="uploadContainer">
                      <FileUploadBg />
                      <input
                        type="file"
                        id="file_upload"
                        accept=".xls,.xlsx,.csv"
                        onChange={handleFileChange}
                      />
                    </div>
                    <span>Supported file formats : xls, xslx, csv</span>
                  </div>
                )}

                <Grid
                  display={"flex"}
                  justifyContent={"flex-end"}
                  onClick={() => dispatch(downloadSampleFile())}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    Downlod Sample File
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Grid className="footerBtn">
              <Button>Continue</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="footerBtn">
          <Button
            disabled={
              !(
                campName.length > 0 &&
                template &&
                (isExisting === "custom" ? file && file_uuid : true)
              )
            }
            onClick={handleCreateCampaign}
          >
            <CreateOutlinedIcon /> Create
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div
      className="campaignWrap"
      style={{
        overflow: "scroll",
        height: "calc(100vh - 70px)",
        width: "86vw",
      }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Campaign
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Campaign customer experience!
            </Typography>
          </div>
        </div>

        <Grid style={{ display: "flex" }} marginBottom="16px">
          <Grid sx={{ width: "100%" }}>
            <TextField
              className="textfieldWrap"
              sx={{ width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchNormalIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Search here"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid className="topWrap" sx={{ ml: "10px" }}>
            <Button onClick={toggleDrawer("right", true)}>
              <ControlPointRoundedIcon /> Create Campaign
            </Button>
          </Grid>
        </Grid>

        <div style={{ height: "70vh", width: "100%" }}>
          <Grid marginTop="20px">
            <ResizableTable
              rowCount={campaign_details?.total ?? 0}
              onTablePaginationChange={handlePaginationModelChange}
              onTableRowClick={handleRowClick}
              data={rows}
              columns={columns}
              paginationDetails={campaign_paginationDetails}
            />
          </Grid>
        </div>
        <div>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
          <Drawer
            anchor={"right"}
            open={openRun}
            onClose={() => {
              setOpenRun(false);
              setEmailDomain(null);
              setSelectedCampaign(null);
            }}
          >
            <Box sx={{ width: 500 }} role="presentation">
              <Grid sx={{ m: "12px" }}>
                <Grid className="textGridwrap">
                  <IconButton
                    className="closeBtn"
                    onClick={() => {
                      setOpenRun(false);
                      setEmailDomain(null);
                      setSelectedCampaign(null);
                    }}
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                  <Typography>Run Campaign</Typography>

                  <div className="d-flex mb10 helpertxt">
                    <Autocomplete

                      disablePortal
                      options={email_domains}
                      fullWidth
                      getOptionLabel={(option) => option.email}
                      onChange={(event, newValue) => setEmailDomain(newValue)}
                      isOptionEqualToValue={(option, value) =>
                        option.uuid === value.uuid
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Email Domains"
                          helperText="Choose the email domain you want to use to send emails. This will be the 'From' address for your campaign."
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid className="footerBtn">
                  <Button disabled={!emailDomain} onClick={handleRunCampaign}>
                   Run
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Drawer>
        </div>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnack}
        message={campaign_snack_message}
        action={action}
        onClose={handleSnackBarClose}
        autoHideDuration={3500}
      />
    </div>
  );
}

export default Campaign;
