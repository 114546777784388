import { useEffect, useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import "./resizableTable.scss";
import React from "react";
import { useState } from "react";
import { Grid } from "@mui/material";

import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { updateSelectionModelChange } from "../../redux/slices/dataTableSlice";
import { useDispatch } from "react-redux";

const ResizableTable = ({
  onTableRowClick,
  paginationDetails,
  onTablePaginationChange,
  columns,
  data,
  rowCount,
  enableRowSelection,
}) => {
  const theme = useTheme();
  const colors = theme.palette;
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [pagination, setPagination] = useState({
    pageIndex: paginationDetails.page,
    pageSize: paginationDetails.pageSize, //customize the default page size
  });
  const [rowSelection, setRowSelection] = useState({}); //ts type available

  const handlePaginationChange = (newPagination) => {
    setPagination(newPagination);
  };

  useEffect(() => {
    console.log(pagination);
    onTablePaginationChange({
      page: pagination.pageIndex,
      pageSize: pagination.pageSize,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(()=>{
    dispatch(updateSelectionModelChange(rowSelection))
  },[rowSelection])

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: "grid",
    //optionally override the default column widths
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 160, //default size is usually 180
    },
    enableColumnResizing: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableFilters: false,
    enableColumnOrdering: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableSorting: false,
    enableTopToolbar: false,
    enableRowSelection: true,
    manualPagination: true,
    rowCount: rowCount,
    onRowSelectionChange:setRowSelection,

    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onTableRowClick(row),
    }),
    onPaginationChange: (newPaginationModel) =>
      handlePaginationChange(newPaginationModel),
    state: { pagination,rowSelection },

    getRowId: (originalRow) => originalRow.userId,
    muiSelectCheckboxProps: {
      size: "small",
    },

    muiSelectAllCheckboxProps: {
      size: "small",
      sx: { marginTop: "-10px" },
    },
    muiTablePaperProps: {
      elevation: 0,
    },

    columnResizeMode: "onChange", //default
  });

  return (
    <Grid className="checkboxTable">
      <MaterialReactTable table={table} />
    </Grid>
  );
};

export default ResizableTable;
