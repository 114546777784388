import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";


import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Tooltip from "@mui/material/Tooltip";
import './sidenav.scss';

import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';



import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import AscendoIcon from "../../assets/AscendoIcon";


const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    //width: `calc(100% - ${drawerWidth}px)`,
    width: `calc(100% - 0)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function MiniDrawer() {
  const theme = useTheme();
  const colors = theme.palette;
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const currentUrl = window.location.href;


  // Split the URL by '/'
  const urlParts = currentUrl.split("/");

  const currentPage = urlParts[3];
 
  const handleDrawerToggle = () => {
    setOpen(!open);
  };



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  
  const handleClose = () => {
    setAnchorEl(null);
  };




  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <div className="headerWrap">
          <div className="headerLeftwrap">
            <Toolbar sx={{ display: "flex", flexDirection: "row" }}>
              {/* <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{ marginRight: 1 }}
                onClick={handleSnackbarOpen}
              >
                <MenudotIcon sx={{ marginRight: "20px" }} />
              </IconButton> */}
              <AscendoIcon />
            </Toolbar>
          </div>
          

          
        </div>
        <div className="headerRightwrap">
          <Box sx={{ width: "5%" }}></Box>
        </div>
      </AppBar>

      
      <div className="outerDrawer">
        <div
          className="arrows"
          onClick={handleDrawerToggle}
          sx={{ marginRight: 5, ...(open && { display: "block" }) }}
        >
          {" "}
          <Tooltip title={open ? "Collapse" : "Expand"}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </Tooltip>
        </div>
        <Drawer variant="permanent" open={open} className="menuWrap">
          <DrawerHeader></DrawerHeader>

         
          <List>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => navigate(`/contact`)}
            >
              <ListItemButton
                className="menuList"
                sx={{
                  justifyContent: open ? "initial" : "center",
                  px: 2,
                  color: colors.ascendo.darkblue,
                  backgroundColor:
                    currentPage === "contact"
                      ? colors.ascendo.yellow
                      : "transparent",
                  "&:hover": {
                    backgroundColor:
                      currentPage === "contact"
                        ? colors.ascendo.yellow
                        : "transparent",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PermContactCalendarOutlinedIcon sx={{color:'#394769'}} />
                </ListItemIcon>
                <ListItemText
                  primary={"Contact"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{ display: "block" }} onClick={() => navigate(`/template`)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  color: colors.ascendo.darkblue,

                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  backgroundColor:
                    currentPage === "template"
                      ? colors.ascendo.yellow
                      : "transparent",
                  "&:hover": {
                    backgroundColor:
                      currentPage === "template"
                        ? colors.ascendo.yellow
                        : "transparent",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <TextSnippetOutlinedIcon sx={{color:'#394769'}} />
                </ListItemIcon>
                <ListItemText
                  primary={"Template"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{ display: "block" }} onClick={() => navigate(`/campaign`)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  color: colors.ascendo.darkblue,

                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  backgroundColor:
                    currentPage === "campaign"
                      ? colors.ascendo.yellow
                      : "transparent",
                  "&:hover": {
                    backgroundColor:
                      currentPage === "campaign"
                        ? colors.ascendo.yellow
                        : "transparent",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <CampaignOutlinedIcon  sx={{color:'#394769'}}/>
                </ListItemIcon>
                <ListItemText
                  primary={"Campaign"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>



          </List>


          {/* <List sx={{ height: "100vh" }}>

            {["Logout"].map((text, index) => (
              <ListItem
                onClick={() => navigate("/logout")}
                key={text}
                disablePadding
                sx={{ display: "block", position: "absolute", bottom: "10px" }}
              >
                <Divider className="borderBottom" />
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {index % 2 === 0 ? <LogoutIcon /> : <LogoutIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List> */}
        </Drawer>

       
      </div>
    </Box>
  );
}
