
import './App.css';
import {Routes,Route,Navigate}from "react-router-dom"
import MiniDrawer from './components/sidenav/sidenav';
import { Provider } from 'react-redux';
import store from "./redux/store/configure-store"
import { ThemeProvider } from '@emotion/react';
import {theme}from "./theme"

import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Contact from './views/contact/Contact';
import Template from './views/template/Template';
import Campaign from './views/campaign/Campaign';


function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
   <div style={{display:"flex"}}>
   <MiniDrawer />
   <Grid mt="70px" style={{display:"flex",width:'100vw'}}> 

   <Routes>
   <Route path="/" element={<Navigate to={`/contact`} />} />

             
              <Route path='/contact' element={<Contact />} />
              <Route path='/template' element={<Template />} />
              <Route path='/campaign' element={<Campaign />} />
            
              
           

   </Routes>
   </Grid>
    
   </div>
   </ThemeProvider>
   </Provider>
   
  );
}

export default App;
