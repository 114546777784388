import React from 'react'

function FileUploadBg() {
  return (
    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="41" cy="41" r="41" fill="#1A73E8" fill-opacity="0.06"/>
<path d="M41.0034 35.3138C40.2859 35.3138 39.6909 34.7188 39.6909 34.0013V26.6688L38.4309 27.9288C37.9234 28.4363 37.0834 28.4363 36.5759 27.9288C36.0684 27.4213 36.0684 26.5813 36.5759 26.0738L40.0759 22.5738C40.4434 22.2063 41.0209 22.0838 41.5109 22.2938C42.0009 22.4863 42.3159 22.9763 42.3159 23.5013V34.0013C42.3159 34.7188 41.7209 35.3138 41.0034 35.3138Z" fill="#1A73E8"/>
<path d="M44.5034 28.312C44.1709 28.312 43.8384 28.1895 43.5759 27.927L40.0759 24.427C39.5684 23.9195 39.5684 23.0795 40.0759 22.572C40.5834 22.0645 41.4234 22.0645 41.9309 22.572L45.4309 26.072C45.9384 26.5795 45.9384 27.4195 45.4309 27.927C45.1684 28.1895 44.8359 28.312 44.5034 28.312Z" fill="#1A73E8"/>
<path d="M48 59.8125H34C23.9375 59.8125 23.9375 54.475 23.9375 49.75V48C23.9375 44.0975 23.9375 39.6875 32.25 39.6875C34.3325 39.6875 35.1025 40.195 36.1875 41C36.24 41.0525 36.31 41.0875 36.3625 41.1575L38.1475 43.0475C39.6525 44.64 42.3825 44.64 43.8875 43.0475L45.6725 41.1575C45.725 41.105 45.7775 41.0525 45.8475 41C46.9325 40.1775 47.7025 39.6875 49.785 39.6875C58.0975 39.6875 58.0975 44.0975 58.0975 48V49.75C58.0625 56.435 54.685 59.8125 48 59.8125ZM32.25 42.3125C26.5625 42.3125 26.5625 44.0975 26.5625 48V49.75C26.5625 54.545 26.5625 57.1875 34 57.1875H48C53.215 57.1875 55.4375 54.965 55.4375 49.75V48C55.4375 44.0975 55.4375 42.3125 49.75 42.3125C48.49 42.3125 48.2275 42.47 47.475 43.03L45.7775 44.8325C44.5175 46.1625 42.82 46.8975 41 46.8975C39.18 46.8975 37.4825 46.1625 36.2225 44.8325L34.525 43.03C33.7725 42.47 33.51 42.3125 32.25 42.3125Z" fill="#1A73E8"/>
<path d="M28.75 42.3124C28.0325 42.3124 27.4375 41.7174 27.4375 40.9999V37.4999C27.4375 34.1049 27.4375 29.8874 33.8775 29.2749C34.5775 29.1874 35.2425 29.7299 35.3125 30.4649C35.3825 31.1824 34.8575 31.8299 34.1225 31.8999C30.0625 32.2674 30.0625 33.9124 30.0625 37.4999V40.9999C30.0625 41.7174 29.4675 42.3124 28.75 42.3124Z" fill="#1A73E8"/>
<path d="M53.2466 42.3124C52.5291 42.3124 51.9341 41.7174 51.9341 40.9999V37.4999C51.9341 33.9124 51.9341 32.2674 47.8741 31.8824C47.1566 31.8124 46.6316 31.1649 46.7016 30.4474C46.7716 29.7299 47.4016 29.1874 48.1366 29.2749C54.5766 29.8874 54.5766 34.1049 54.5766 37.4999V40.9999C54.5591 41.7174 53.9641 42.3124 53.2466 42.3124Z" fill="#1A73E8"/>
</svg>

  )
}

export default FileUploadBg